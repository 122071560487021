import React, { useEffect, useState } from "react";
import { TimeRange } from "@matiaslgonzalez/react-timeline-range-slider";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { MyDiv, PTag } from "../Common/Components";
import { FaClock, FaDollarSign } from "react-icons/fa6";
import swal from "sweetalert2";
import CryptoJS from "crypto-js";
import { loadStripe } from '@stripe/stripe-js';
import { TextField, MenuItem, FormControlLabel, Select, Radio, Autocomplete, Checkbox, Card, CardMedia, Typography, RadioGroup, FormLabel, FormControl } from '@mui/material';
const stripePromise = loadStripe('YOUR_PUBLIC_KEY');
const PAYLOADKEY = process.env.REACT_APP_PAYLOAD_SECRET_KEY;
const PAT = process.env.REACT_APP_PATIENT_ROLEID;
const AvailableSlots = ({ therapistId, date, costperhour, singlecost, couplecost, formData}) => {
  const [disabledIntervals, setDisabledIntervals] = useState([]);
  const [range, setRange] = useState([null, null]);
  const [timelineBounds, setTimelineBounds] = useState([null, null]);
  const [isInvalidRange, setIsInvalidRange] = useState(false);
  const [durationHours, setDurationInHours] = useState();
  const [sumcost, setSumCost] = useState();
  

  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (user.token && (user.mode === 'doctor')) {
  //     navigate('/');
  //   }
  // }, []);  
  const [specialistAvailability, setSpecialistAvailability] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [therapyType, setTherapyType] = useState(null);
  const [selectedInfo, setSelectedInfo] = useState('');


useEffect(() => {
    if (formData) {
  console.log(formData,"formData")

        // Parse the time and create valid Date objects
        const today = new Date(formData.selectedDate);
        
        const parseTime = (timeStr) => {
          if (!timeStr || !timeStr.includes(':')) return null;
          const [hours, minutes] = timeStr.split(':').map(Number);
          if (isNaN(hours) || isNaN(minutes)) return null;

          const date = new Date(today);
          date.setHours(hours, minutes, 0, 0);
          return date;
      };

        const startDate = parseTime(formData.selectedTimeSlotStart);
        const endDate = parseTime(formData.selectedTimeSlotEnd);
  console.log(startDate,endDate,formData.selectedInfo,"newRange")

        setRange([startDate, endDate]);
        setSelectedInfo(formData.selectedInfo);
    }else{
  console.log("FORMdATA NOT FOUND")

    }
}, [formData]);


  const handleInfoChange = (info)=>{
    console.log(info,"info")
    setSelectedInfo(info.target.value);
  }
  const handleBookAppointment = (doctor) => {
    if(selectedInfo == ""){
      swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please fill up the appointment info!",
      });
      return false;
    }
    const decryptData = (encryptedData, ivString) => {
    const iv = CryptoJS.enc.Hex.parse(ivString);
    const decrypted = CryptoJS.AES.decrypt(
      encryptedData,
      CryptoJS.enc.Utf8.parse(PAYLOADKEY),
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)).mode;
  };
    if (user.token && decryptData(user.mode, user.string) === PAT) {
      // window.href = `/patient/appointment-booking/${doctor}`
      if (date && range) {
        const bookingData = {
          date: selectedDate,
          timeSlot: moment(range[0]).format("hh:mm")+"-"+moment(range[1]).format("hh:mm"),
          shortInfo: selectedInfo
        };
        const therapyfees = therapyType ? (therapyType == "Individual" ? "individual_fees" : "couple_fees") : "consulting_fees"
        navigate('/patient/appointment-pay', {
          state: {
            id: therapistId, selectedDate: date, selectedTimeSlotStart: moment(range[0]).format("hh:mm"),selectedTimeSlotEnd: moment(range[1]).format("hh:mm"), selectedInfo,
            doc_Profile_Image: specialistAvailability?.docData?.doc_Profile_Image,
            doc_First_Name: specialistAvailability?.docData?.doc_First_Name,
            consulting_fees_type: therapyfees
          }
        })
      } else {
        swal.fire({
          text: 'Please select date, time slot and additional information before booking',
          icon: 'warning',
          type: 'warning',  
        });
      }
      // navigate(`/patient/appointment-booking/${doctor}`);
    } else if (!user.userId) {
      const therapyfees = therapyType ? (therapyType == "Individual" ? "individual_fees" : "couple_fees") : "consulting_fees"
      const otherData = {
        id: therapistId, selectedDate: date, selectedTimeSlotStart: moment(range[0]).format("hh:mm"),selectedTimeSlotEnd: moment(range[1]).format("hh:mm"), selectedInfo,
        doc_Profile_Image: specialistAvailability?.docData?.doc_Profile_Image,
        doc_First_Name: specialistAvailability?.docData?.doc_First_Name,
        consulting_fees_type: therapyfees
      }
      localStorage.setItem('redirectUrl', window.location.pathname);
      localStorage.setItem('formData', JSON.stringify(otherData));
      console.log(window.location.pathname,"window.location.pathname")
      swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please Login / Register as a Client",
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: 'Register',
        denyButtonText: 'Login',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/signup');
        } else if (result.isDenied) {
          navigate('/login');
        }      
      });
    } else {
      swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "You are not a Client!",
      });
    }
  };
  useEffect(() => {
    const fetchSlots = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}fetch-doc-availability-new/${therapistId}?date=${date}`,
      {
        headers: {
          "x-access-token": user.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    // Parse and convert slots into start and end timestamps
    let slots = response?.data?.data?.workingHours?.map((slot) => {
      const start = moment(`${date} ${slot.startTime}`, "YYYY-MM-DD HH:mm").valueOf();
      let end = moment(`${date} ${slot.endTime}`, "YYYY-MM-DD HH:mm").valueOf();

      // Handle cases where `endTime` is on the next day
      if (moment(slot.endTime, "HH:mm").isBefore(moment(slot.startTime, "HH:mm"))) {
        end = moment(`${date} ${slot.endTime}`, "YYYY-MM-DD HH:mm").add(1, "day").valueOf();
      }

      return { start, end };
    });

    // Sort slots by start time
    slots = slots?.sort((a, b) => a.start - b.start);

    // Merge adjacent or overlapping slots
    const mergedSlots = [];
    slots?.forEach((slot) => {
      if (mergedSlots.length === 0) {
        mergedSlots.push(slot);
      } else {
        const lastSlot = mergedSlots[mergedSlots.length - 1];
        if (slot.start <= lastSlot.end) {
          // Merge slots by updating the end time
          lastSlot.end = Math.max(lastSlot.end, slot.end);
        } else {
          mergedSlots.push(slot);
        }
      }
    });

    // Calculate timeline bounds
    const currentTime = moment().valueOf(); // Current timestamp in milliseconds
    const minSlotTime  = Math.min(...mergedSlots?.map((slot) => slot.start));
    const maxTime = Math.max(...mergedSlots?.map((slot) => slot.end));
    const minTime = Math.max(minSlotTime, currentTime); // Ensure minTime is greater than the current time
    if (minTime !== Infinity && maxTime !== -Infinity) {
      setTimelineBounds([minTime, maxTime]);
    }

    // Compute unavailable intervals
    const unavailableIntervals = [];
    if (mergedSlots?.length > 0) {
      // Add interval before the first slot
      if (mergedSlots[0].start > minTime) {
        unavailableIntervals.push({ start: minTime, end: mergedSlots[0].start });
      }

      // Add gaps between slots
      for (let i = 0; i < mergedSlots?.length - 1; i++) {
        unavailableIntervals.push({
          start: mergedSlots[i].end,
          end: mergedSlots[i + 1].start,
        });
      }

      // Add interval after the last slot
      if (mergedSlots[mergedSlots?.length - 1].end < maxTime) {
        unavailableIntervals.push({
          start: mergedSlots[mergedSlots?.length - 1].end,
          end: maxTime,
        });
      }
    } else {
      // If no slots are available, mark the entire day as unavailable
      unavailableIntervals.push({ start: minTime, end: maxTime });
    }
    setDisabledIntervals(unavailableIntervals);
  } catch (error) {
    console.error("Error fetching slots:", error);
  }
};
fetchSlots();

  }, [therapistId, date]);
  
console.log(timelineBounds)
  const handleRangeChange = (newRange) => {
  setRange(newRange);
  console.log(newRange, "newRange");

  // Check if the selected range overlaps with any disabled intervals
  const isInvalid = disabledIntervals.some((interval) =>
    // Overlap conditions:
    // 1. Selected range starts within a disabled interval
    (newRange[0] >= interval.start && newRange[0] < interval.end) ||
    // 2. Selected range ends within a disabled interval
    (newRange[1] > interval.start && newRange[1] <= interval.end) ||
    // 3. Selected range fully contains a disabled interval
    (newRange[0] <= interval.start && newRange[1] >= interval.end)
  );

  setIsInvalidRange(isInvalid);
};


  useEffect(()=>{
    const [start, end] = range;
    const startTime = moment(start);
    const endTime = moment(end);
  
    // Calculate the difference in hours
    const durationInHours = endTime.diff(startTime, "hours", true); // Includes fractions for partial hours
    setDurationInHours(durationInHours)
    switch (therapyType) {
      case "Individual":
        setSumCost(durationInHours * singlecost);
        break;
      case "Couple":
        setSumCost(durationInHours * couplecost);
        break;
      default:
        setSumCost(durationInHours * costperhour);
        break;
    }

        
    console.log(therapyType,"therapyType")
  },[range,therapyType])

  useEffect(()=>{
    if(singlecost > 0 && couplecost > 0){
      setTherapyType("Individual")
    }else if(singlecost > 0){
      setTherapyType("Individual")
    }else if(couplecost > 0){
      setTherapyType("Couple")
    }else{
      setTherapyType(null)
    }
  },[singlecost])
  

  const handleBooking = async () => {
    try {
      await axios.post("/api/book-slot", {
        therapistId,
        date,
        startTime: moment(range[0]).format("HH:mm"),
        endTime: moment(range[1]).format("HH:mm"),
      });
      alert("Booking confirmed!");
    } catch (error) {
      console.error("Error booking slot:", error);
    }
  };

  const changeTherapyType = (e) => {
    setTherapyType(e.target.value)
  };
  // console.log("Transformed Timeline Bounds:", minTime, maxTime);
  
  return (
    <MyDiv>
      <br/>
      <div className={isInvalidRange ? "invalid-range-wrapper" : ""}>
      {(timelineBounds[0] && timelineBounds[1]) ? (
        <>
          
          <PTag><b>Choose a time</b></PTag>

          {timelineBounds &&
          <TimeRange
            ticksNumber={30}
            onChangeCallback={handleRangeChange}
            selectedInterval={range}
            timelineInterval={timelineBounds}
            disabledIntervals={disabledIntervals}
            formatTick={(ms) => moment(ms).format("hh:mm A")} // Display time in AM/PM format
            className={isInvalidRange ? "invalid-range" : ""} // Apply red border for invalid range
          />
        }

        </>
      ) : 
        <PTag><b>No time slots available</b></PTag>
      }
      </div>

      {singlecost > 0 && couplecost > 0 && durationHours?
        (
          <>
          <div>
          <TextField select required variant="outlined" defaultValue={therapyType} onChange={(e)=>changeTherapyType(e)} size="medium" label="Choose your therapy" id="therapyType" name="therapyType" className="form-control"  >
            <MenuItem value="Individual">Individual Therapy</MenuItem>
            <MenuItem value="Couple">Couple Therapy</MenuItem>
          </TextField>
          </div><br/><br/></>
        ) : ""
      }
      

      {isInvalidRange && <p style={{ color: "red" }}>Selected range is unavailable.</p>}
      {durationHours ? <p><b><FaClock/> Total hours:</b> {moment(range[0]).format("hh:mm A")} - {moment(range[1]).format("hh:mm A")}, {durationHours} hours</p> : ""}
      {sumcost ? <p><b><FaDollarSign/>Total payable:</b> {sumcost}</p> : ""}<br/><br/>
      {
        (sumcost && durationHours && isInvalidRange == false) ?
      <><TextField required type="text" id="appointment_info" name="appointment_info" fullWidth multiline minRows={5} margin="dense" label="Appointment Info" defaultValue={selectedInfo} variant="outlined" InputLabelProps={{ shrink: true }} inputProps={{ maxLength: 725 }} onChange={(e)=>handleInfoChange(e)} placeholder="Please let us know what issues you are currently facing, so we can help you better :)"/><br/><br/>
          <PTag><b><em>Booking for the first time? 30 mins on us 🎉</em></b></PTag>

      <MyDiv className="bookBtn">

      <MyDiv className="animatedBtn" onClick={() => handleBookAppointment(therapistId)} disabled={!range[0] || !range[1]} style={{    display: "block",
    height: "50px",
    lineHeight: "50px",
    fontSize: "22px",
    borderRadius: "5px",
    textAlign: "center", cursor:"pointer"}}>
        Book Now
      </MyDiv>
      </MyDiv> </>: ""
}
    </MyDiv>
  );
};

export default AvailableSlots;
