import React, { useEffect, useState } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { HeadingFive, HeadingFour, MyDiv, PTag } from "../Common/Components";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SpecialistProfileSideBar from './sidebar';
import MainHeader from '../Common/header';
import { Col, Row } from 'react-bootstrap';
import { Verified } from '@mui/icons-material';
import { FaBagShopping, FaClock, FaDollarSign, FaIdBadge, FaLocationDot, FaPaperclip, FaPen, FaPersonWalkingLuggage } from 'react-icons/fa6';
import TherapistProfileSlots from './booking-new';
// import SpecialistTemplate from './template';





export default function TherapistProfileCore({profileData}) {  

  const cleanText = (text) => {
    // Replace occurrences of "\n" with an actual newline and handle repetitive slashes
    return text.replace(/\\n/g, "\n").replace(/\\+/g, "");
  };



  return (
    <React.Fragment>
    <MyDiv className="UserPanel">
            <MyDiv className="GeneralContainer">
            <MyDiv className="GeneralContentBg">
            <MyDiv className="GeneralContentBgInner flex">
                {/* <MyDiv className="LeftSideContainer"> */}
        <MyDiv className="MeetOurSection">
        <Row>
        {/* <SpecialistProfileSideBar profileData={profileData}/> */}
        <Col
                    lg={8,{order: 1 }}
                    md={6}
                    sm={6}
                    xs={12,{order: 2 }}
                    xl={9}
                    className="box-single"          
                  >
                  <MyDiv style={{height:"auto",width:"95%",margin:"auto"}}>
                    <MyDiv className="Right-Side-Main-Container">
                        <Row><strong><PTag>Hi, I am {profileData.doc_First_Name+" "+profileData.doc_Last_Name} 👋</PTag></strong>
                        <PTag>{cleanText(profileData.doc_About)}</PTag></Row>
                        <Row>
                        <Col 
                                  lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    xl={6}
                    >
                        {/* <strong><HeadingFour>Preferences</HeadingFour></strong>
                        <PTag>{profileData.doc_About}</PTag> */}
                          {profileData.doc_Work_History &&
                          profileData.doc_Work_History.length > 0 ?
                          
                          (
                            
                            //{index == profileData?.doc_SkillAndPreferences?.skills?.length ?  null : ','}

                            profileData.doc_Work_History.map(
                              (work, index) => {
                                return (
                                  <>
                        {index == 0 ? <><br/><strong><HeadingFive className="green"><FaIdBadge/> Experience</HeadingFive></strong><br/></> : ""}

                        <PTag>
                                  <span key={index}>
                                    <b>{work.role}</b><br/>
                                    {work.institution}<br/>
                                    {work.years_of_experience} Years<br/>
                                    {index < profileData.doc_Work_History.length - 1 ? <hr/> : null}
                                  </span>
                                  </PTag>
                                  </>
                          )})
                                ) : ""
                              }
                              </Col>
                              <Col 
                                  lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    xl={6}
                    >
                        {profileData.doc_Education &&
                          profileData.doc_Education.length > 0 ?
                          
                          (
                            
                            //{index == profileData?.doc_SkillAndPreferences?.skills?.length ?  null : ','}

                            profileData.doc_Education.map(
                              (work, index) => {
                                return (
                                  <>
                                  
                        {index == 0 ? <><br/><strong><HeadingFive className="green"><FaPen/> Education</HeadingFive></strong><br/></> : ""}

                        <PTag>
                                  <span key={index}>
                                    <b>{work.institution+", "+work.certificate_province}</b><br/>
                                    {work.field_of_study}<br/>
                                    {index < profileData.doc_Education.length - 1 ? <hr/> : null}
                                  </span>
                                  </PTag>
                                  </>
                          )})
                                ) : ""
                              }
                                  </Col>

                        </Row>
                        <Row>
                          <hr/>
                          <br/><strong><HeadingFive className="green">Let's Book?</HeadingFive></strong><br/><br/>
                          <TherapistProfileSlots therapistId={profileData._id} availability={profileData.availability} costperhour={profileData.doc_Fee.consulting_fees} singlecost={profileData.doc_Fee.individual_fees ? profileData.doc_Fee.individual_fees : 0} couplecost={profileData.doc_Fee.couple_fees ? profileData.doc_Fee.couple_fees : 0}/>
                        </Row>
                    </MyDiv>
                    
                </MyDiv>
                </Col>
        <Col
                    lg={4,{order: 2 }}
                    md={6}
                    sm={6}
                    xs={12,{order: 1 }}
                    xl={3}
                    className="box-single"
                  >
                    <MyDiv className="HomeDocDetails" style={{margin:"0"}}>
                      <MyDiv className="HomeDocDetailsImg">
                        {profileData.doc_Profile_Image !== null ? (
                          <img
                            src={`${process.env.REACT_APP_API_BASE_URL}${profileData.doc_Profile_Image}`}
                            alt={profileData.doc_First_Name}
                          />
                        ) : (
                          <img
                            src={require("../../assets/images/NoImage.jpg")}
                            alt={profileData.doc_First_Name}
                          />
                        )}
                      </MyDiv>
                      <MyDiv className="HomeDocDetailsTxt">
                  <span className="HomeDocDetailsHeader">
                      <h5>
                        {profileData.doc_First_Name+' '+profileData.doc_Last_Name} 
                      </h5>

                      <p style={{fontSize: ".75rem"}} className="clr-6e">
                            {`${profileData?.doc_Contact_Info?.city}, ${profileData?.doc_Contact_Info?.state}`}
                      </p>

                  <div className="d-flex align-items-start gapPt3 f-sm"><Verified className="verifird-badge" /> <p className="f-sm" style={{color: "#6e6e6e"}}>Verified</p> </div>
                  </span>
                        {/* <div className="d-flex justify-content-between align-items-center">

                        <span
                        
                        style={{background: "green", color: "white", padding: ".2rem", borderRadius: ".3rem", fontSize: "small", marginBottom: ".5rem"}}
                        className="d-flex align-items-center gapPt5"

                      >
                        Verified
                        <FaCheck style={{background: "var(--linkText)", borderRadius: "50%", padding: ".1rem"}} fontSize="small"/>
                      </span>
                        
                      </div> */}

                        
                       

                        {/* <HeadingSix>{profileData.doc_About}</HeadingSix> */}
                        <MyDiv className="HomeDocSkills">
                          {profileData.doc_SkillAndPreferences &&
                          profileData.doc_SkillAndPreferences.skills &&
                          profileData.doc_SkillAndPreferences.skills.length > 0 ?
                          
                          (
                            
                            //{index == profileData?.doc_SkillAndPreferences?.skills?.length ?  null : ','}

                            profileData.doc_SkillAndPreferences.skills.map(
                              (skill, index) => {
                                return (
                                  <span key={index}>
                                    {skill}
                                    {index < profileData.doc_SkillAndPreferences.skills.length - 1 ? ', ' : null}
                                  </span>
                                );                                

                                
                                // if(index <= 2)
                                //   return <span key={index}>{skill} {profileData.doc_SkillAndPreferences.skills.length == index ? `,`: null }</span>
                                // else if(index == (profileData.doc_SkillAndPreferences.skills.length -1))
                                //   return <span style={{color: "var(--bg-contrast)", fontWeight: "bold"}}>+{(profileData.doc_SkillAndPreferences.skills.length -1)- 3} more</span>
                               
                               
                                // else
                                
                               
                                //  <span key={index}>{index <= 3 ? `${skill},` : index == 4 ? `...` : ''}</span>
                              }
                            )
                          ) : (
                            <span>No skills available</span>
                          )}
                        </MyDiv>
                        {/* <PTag>
                          <FaLocationDot />

                            {`${profileData?.doc_Contact_Info?.city}, ${profileData?.doc_Contact_Info?.state}`}

                        </PTag> */}
                        <PTag>

                        {profileData?.doc_province?.length > 0 && 
                              <>
                          
                          <FaLocationDot
                          
                           style={{marginRight: ".2rem"}}
                           
                           /> 
                          {/* {profileData.doc_Contact_Info.hasOwnProperty('state') ? `${profileData?.doc_Contact_Info?.state} - ${profileData?.doc_Contact_Info?.city}` : `${profileData?.doc_Contact_Info?.city}`}{profileData?.doc_province?.length > 0 ? ', ' : '' }  */}
                          
                            
                                {profileData.doc_province?.slice(0, 3).map((location, index) => 
                                  location?.certificate_province ? (
                                    <span key={index}>
                                      {location.certificate_province}
                                      {/* Add a comma if it's not the last item displayed */}
                                      {index < 2 && profileData.doc_province?.length > 1 && index < profileData?.doc_province?.length - 1  ? ', ' : ''}
                                    </span>
                                  ) : null
                                )}
                                {profileData.doc_province?.length > 3 && (
                                  <span style={{ color: "var(--bg-contrast)", fontWeight: "bold" }}>
                                    + {profileData.doc_province?.length - 3} more
                                  </span>
                                )}
                              </>
                          }

                        </PTag>
                        <PTag>
                          <strong>C<FaDollarSign/>{profileData.doc_Fee.consulting_fees}</strong> / hour
                        </PTag>
                        {/* <MyDiv className="bookBtn">
                          {" "}
                          <Link
                            onClick={() => handleBookAppointment(doctor)}
                            className="animatedBtn"
                          >
                            Book
                          </Link>
                        </MyDiv> */}
                      </MyDiv>
                    </MyDiv>
                  </Col>
                  
                </Row>
        {/* </MyDiv> */}
        </MyDiv>        
        
        </MyDiv>        
        </MyDiv>
        </MyDiv>
        </MyDiv>
      <Outlet />
      </React.Fragment>
  );
}
