import * as React from "react";
import { Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import TemplateCore from "./Pages/template"
import PatientTemplate from "./Pages/Patients/template"
import SpecialistTemplate from "./Pages/Specialist/template"
import SuperAdminTemplate from "./Pages/SuperAdmin/template"
import RequireAuth from "./Pages/RequireAuth";
import TherapistProfileCore from "./Pages/Profile";


const PAT = process.env.REACT_APP_PATIENT_ROLEID
const SUPER = process.env.REACT_APP_SUPERADMIN_ROLEID
const SPECIAL = process.env.REACT_APP_SPECIALIST_ROLEID
const WebsiteCore = React.lazy(() => import('./Pages/Website'));
const CoreLogin = React.lazy(() => import('./Pages/Auth/login'));
const CoreForgotPassword = React.lazy(() => import("./Pages/Auth/forgot-password"));
const CoreRegister = React.lazy(() => import("./Pages/Auth/register"));
const CoreSpecialistRegister = React.lazy(() => import("./Pages/Auth/Specialist-register"));
const PatientCore = React.lazy(() => import("./Pages/Patients"));
const SpecialistCore = React.lazy(() => import("./Pages/Specialist"));
const SuperAdminCore = React.lazy(() => import("./Pages/SuperAdmin"));
const SignupType = React.lazy(() => import("./Pages/Auth/signupType"));

function App() {

  return (
    <Routes>
      <Route path="/*" element={<React.Suspense fallback={<>...</>}><WebsiteCore /></React.Suspense>} />
      <Route path="login" element={<React.Suspense fallback={<>...</>}><CoreLogin /></React.Suspense>} />
      <Route path="signup-type" element={<React.Suspense fallback={<>...</>}><SignupType /></React.Suspense>} />
      <Route path="forgot-password" element={<React.Suspense fallback={<>...</>}><CoreForgotPassword /></React.Suspense>} />
      <Route path="signup" element={<React.Suspense fallback={<>...</>}><CoreRegister /></React.Suspense>} />
      <Route path="therapist-signup" element={<React.Suspense fallback={<>...</>}><CoreSpecialistRegister /></React.Suspense>} />


      {/* <Route path="update-password/:id" element={<React.Suspense fallback={<>...</>}><CoreUpdatePassword /></React.Suspense>} /> */}
      <Route element={<TemplateCore />}>
      </Route>

      <Route element={<RequireAuth role={PAT} />}>
        <Route element={<PatientTemplate />}>
          <Route path="/patient/*" element={<React.Suspense fallback={<>...</>}><PatientCore /></React.Suspense>} />
        </Route>
      </Route>

      <Route element={<RequireAuth role={SPECIAL} />}>
        <Route element={<SpecialistTemplate />}>
          <Route path="/therapist/*" element={<React.Suspense fallback={<>...</>}><SpecialistCore /></React.Suspense>} />
        </Route>
      </Route>

      <Route element={<RequireAuth role={SUPER} />}>
        <Route element={<SuperAdminTemplate />}>
          <Route path="/admin/*" element={<React.Suspense fallback={<>...</>}><SuperAdminCore /></React.Suspense>} />
        </Route>
      </Route>

    </Routes>
  );
}

export default App;
