import React, { useState, useEffect } from "react";
import AvailableSlots from "./AvailableSlots";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { useLocation } from 'react-router-dom';


const TherapistProfileSlots = ({ therapistId,availability,costperhour,singlecost,couplecost }) => {
    const location = useLocation();

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCalendarDate, setSelectedCalendarDate] = useState(null);
  const [formData, setFormData] = useState(null);
useEffect(() => {
      if (location.state?.formData) {
        setFormData(location.state.formData);
      }
  }, [location.state]);
  useEffect(()=>{
    if(formData){
      console.log(formData,"formData")

      const date = moment(formData.selectedDate);
      setSelectedDate(formData.selectedDate);
      setSelectedCalendarDate(date);
    }
  },[formData])
  console.log(costperhour,"costperhour")
  // const isDateAvailable = ( date ) => {
  //   const weekday = moment(date).format("dddd"); // Get weekday name
  //   const dayData = availability.find((item) => item.day === weekday); // Find matching day
  //   // If day doesn't exist or has no working hours, disable it
  //   return !dayData || dayData?.workingHours?.length === 0;
  // }

  const isDateAvailable = (date) => {
    // Normalize date to midnight local time

    const normalizedDate = new Date(date);
    const today = new Date();
    
    normalizedDate.setHours(0, 0, 0, 0); // Reset to midnight
    today.setHours(0, 0, 0, 0); // Reset to midnight

    if(normalizedDate < today){
      return true;
    }
    const weekday = moment(normalizedDate).format("dddd"); // Get weekday name
    const dayData = availability.find((item) => item.day === weekday); // Find matching day

    // If day doesn't exist or has no working hours, disable it
    return !dayData || dayData?.workingHours?.length === 0;
  };

  const handleDateChange = (event) => {
    const date = moment(event).startOf("day").format("YYYY-MM-DD");
    setSelectedDate(date);
    setSelectedCalendarDate(moment(date));
  };

  return (
    <>
      <Calendar
        onChange={handleDateChange} // Function to set the selected date
        value={selectedCalendarDate}
        tileDisabled={({ date }) => isDateAvailable(date)} // Disable unavailable days
        style={{width:"100%"}}
        className={"react-calendar-custom"}
      />
      {selectedDate && (
        <AvailableSlots therapistId={therapistId} date={selectedDate} costperhour={costperhour} singlecost={singlecost} couplecost={couplecost}  formData={formData}/>
      )}
    </>
  );
};

export default TherapistProfileSlots;
